import * as React from 'react'
import * as styles from './title.module.scss'
import { cls } from '../../utils/utils'

export const Title = (props) => (
  <div className={cls(styles.container, props.className)}>
    <a className={styles.anchor} id={props.id} />
    <h1 className={cls(styles.title, styles.bigCircle, styles[`${props.color}Circle`])}>{props.children}</h1>
  </div>
)
