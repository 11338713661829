import * as React from 'react'
import { Title } from '../../components/title/title'
import * as styles from './partners.module.scss'
import fkn from '../../images/partners/fkn.png'
import itforfree from '../../images/partners/itforfree.png'
import profkom_vgtu from '../../images/partners/profkom_vgtu.png'
import ITMeeting from '../../images/partners/ITMeeting.png'
import lispako from '../../images/partners/lispako.png'
import vgtu from '../../images/partners/vgtu.png'
import vivt from '../../images/partners/vivt.svg'
import vsu from '../../images/partners/vsu.png'
import pm_logo from '../../images/partners/pm_logo.png'
import ict2go from '../../images/partners/ict2go.png'
import itEvents from '../../images/partners/it-events.png'
import UITS from '../../images/partners/UITS.png'
import cossa_logo from '../../images/partners/cossa_logo.png'
import tproger from '../../images/partners/tproger.png'
import logo36On from '../../images/partners/36on.png'
import hexlet from '../../images/partners/hexlet.png'
import proJvm from '../../images/partners/projvm-logo.png'
import rubyKRD from '../../images/partners/rubykrd_logo.jpeg'
import rubyRush from '../../images/partners/rubyrush.png'
import { cls } from '../../utils/utils'

export const PartnersTemplate = () => {
  return (
    <section className={styles.section}>
      <Title color="green" className={styles.title}>
        Партнеры
      </Title>

      <div className={cls('main-container', styles.partners, styles.desktop)}>
        <a href="https://vsuet.ru/obuchenie/faculties/uits" target="_blank"className={styles.partnersLink} >
          <img src={UITS} className={styles.logo} alt='' />
        </a>
        <a href="http://www.cs.vsu.ru/" target="_blank"className={styles.partnersLink} >
          <img src={fkn} className={styles.logo} alt="" />
        </a>
         <a href="https://tproger.ru/events/" target="_blank"className={styles.partnersLink} >
           <img src={tproger} className={styles.logo} alt="" />
         </a>
         <a href="http://www.amm.vsu.ru/" target="_blank"className={styles.partnersLink} >
           <img src={pm_logo} className={styles.logo} alt="" />
         </a>
         <a href="https://vk.com/fitkb_vgtu" target="_blank"className={styles.partnersLink} >
           <img src={vgtu} className={styles.logo} alt="" />
         </a>
      </div>
      <div className={cls('main-container', styles.partners, styles.desktop)}>
        <a href="https://lispako.ru/" target="_blank"className={styles.partnersLink} >
          <img src={lispako} className={styles.logo} alt="" />
        </a>
        <a href="https://36on.ru/" target="_blank"className={styles.partnersLink} >
          <img src={logo36On} className={styles.logo} alt="" />
        </a>
        <a href="https://www.cossa.ru/" target="_blank"className={styles.partnersLink} >
          <img src={cossa_logo} className={styles.logo} alt="" />
        </a>
        <a href="https://ict2go.ru/" target="_blank"className={styles.partnersLink} >
          <img src={ict2go} className={styles.logo} alt="" />
        </a>
        <a href="https://www.vivt.ru/" target="_blank"className={styles.partnersLink} >
          <img src={vivt} className={styles.logo} alt=""/>
        </a>
      </div>
      <div className={cls('main-container', styles.partners, styles.desktop)}>
        <a href="https://vk.com/profkom_vgtu" target="_blank" className={styles.partnersLink}>
          <img src={profkom_vgtu} className={styles.logo} alt="" />
        </a>
        <a href="http://fkn.ktu10.com/?q=iff-itforfree" target="_blank" className={styles.partnersLink}>
          <img src={itforfree} className={styles.logo} alt="" />
        </a>
        <a href="https://t.me/ITMeeting" target="_blank" className={styles.partnersLink}>
          <img src={ITMeeting} className={styles.logo} alt="" />
        </a>
        <a href="https://it-events.com/" target="_blank" className={styles.partnersLink}>
          <img src={itEvents} className={styles.logo} alt="" />
        </a>
        <a href="http://www.vsu.ru/" target="_blank" className={styles.partnersLink}>
          <img src={vsu} className={styles.logo} alt=""/>
        </a>
      </div>
      <div className={cls('main-container', styles.partners, styles.desktop)}>
        <a href="https://t.me/joinchat/1FpbHYe2a-1kMjUy" target="_blank" className={styles.partnersLink}>
          <img src={proJvm} className={styles.logo} alt="" />
        </a>
        <a href="https://ru.hexlet.io/" target="_blank" className={styles.partnersLink}>
          <img src={hexlet} className={styles.logo} alt=""/>
        </a>
        <a href="http://rubykrd.ru/" target="_blank" className={styles.partnersLink}>
          <img src={rubyKRD} className={styles.logo} alt=""/>
        </a>
        <a href="https://rubyrush.ru/" target="_blank" className={styles.partnersLink}>
          <img src={rubyRush} className={styles.logo} alt=""/>
        </a>
      </div>

      <div className={cls('main-container', styles.partners, styles.mobile)}>
        <a href="https://vsuet.ru/obuchenie/faculties/uits" target="_blank" className={styles.logoContainer}>
          <img src={UITS} className={styles.logo} alt="" />
        </a>
        <a href="http://www.cs.vsu.ru/" target="_blank" className={styles.logoContainer}>
          <img src={fkn} className={styles.logo} alt="" />
        </a>
        <a href="https://tproger.ru/events/" target="_blank" className={styles.logoContainer}>
          <img src={tproger} className={styles.logo} alt="" />
        </a>
        <a href="http://www.amm.vsu.ru/" target="_blank" className={styles.logoContainer}>
          <img src={pm_logo} className={styles.logo} alt="" />
        </a>
        <a href="https://vk.com/fitkb_vgtu" target="_blank" className={styles.logoContainer}>
          <img src={vgtu} className={styles.logo} alt="" />
        </a>
        <a href="https://lispako.ru/" target="_blank" className={styles.logoContainer}>
          <img src={lispako} className={styles.logo} alt="" />
        </a>
        <a href="https://36on.ru/" target="_blank" className={styles.logoContainer}>
          <img src={logo36On} className={styles.logo} alt="" />
        </a>
        <a href="https://www.cossa.ru/" target="_blank" className={styles.logoContainer}>
          <img src={cossa_logo} className={styles.logo} alt="" />
        </a>
        <a href="https://ict2go.ru/" target="_blank" className={styles.logoContainer}>
          <img src={ict2go} className={styles.logo} alt="" />
        </a>
        <a href="https://www.vivt.ru/" target="_blank" className={styles.logoContainer}>
          <img src={vivt} className={styles.logo} alt="" />
        </a>
        <a href="https://vk.com/profkom_vgtu" target="_blank" className={styles.logoContainer}>
          <img src={profkom_vgtu} className={styles.logo} alt="" />
        </a>
        <a href="http://fkn.ktu10.com/?q=iff-itforfree" target="_blank" className={styles.logoContainer}>
          <img src={itforfree} className={styles.logo} alt="" />
        </a>
        <a href="https://t.me/ITMeeting" target="_blank" className={styles.logoContainer}>
          <img src={ITMeeting} className={styles.logo} alt="" />
        </a>
        <a href="https://it-events.com/" target="_blank" className={styles.logoContainer}>
          <img src={itEvents} className={styles.logo} alt="" />
        </a>
        <a href="http://www.vsu.ru/" target="_blank" className={styles.logoContainer}>
          <img src={vsu} className={styles.logo} alt="" />
        </a>
        <a href="https://t.me/joinchat/1FpbHYe2a-1kMjUy" target="_blank" className={styles.logoContainer}>
          <img src={proJvm} className={styles.logo} alt="" />
        </a>
        <a href="https://ru.hexlet.io/" target="_blank" className={styles.logoContainer}>
          <img src={hexlet} className={styles.logo} alt="" />
        </a>
        <a href="http://rubykrd.ru/" target="_blank" className={styles.logoContainer}>
          <img src={rubyKRD} className={styles.logo} alt="" />
        </a>
        <a href="https://rubyrush.ru/" target="_blank" className={styles.logoContainer}>
          <img src={rubyRush} className={styles.logo} alt="" />
        </a>
      </div>
    </section>
  )
}
