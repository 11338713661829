// extracted by mini-css-extract-plugin
export var section = "schedule-module--section--1NT6A";
export var title = "schedule-module--title---zgFL";
export var sectionTitle = "schedule-module--section-title--3XlXn";
export var titleCaption = "schedule-module--title-caption--1JO0V";
export var techLabel = "schedule-module--tech-label--XG2sU";
export var techLabelSandy = "schedule-module--tech-label--sandy--Q_ZNt";
export var techLabelGreen = "schedule-module--tech-label--green--vTL8g";
export var lectureTechLabel = "schedule-module--lecture-tech-label--smZKK";
export var speakerCaption = "schedule-module--speaker-caption--1CdhX";
export var desktop = "schedule-module--desktop--2qOI7";
export var grid = "schedule-module--grid--vnOS-";
export var time = "schedule-module--time--7-EWu";
export var timeStart = "schedule-module--time-start--RX3Ay";
export var schedule = "schedule-module--schedule--3tqfn";
export var scheduleStart = "schedule-module--schedule-start--9NP6L";
export var scheduleEnd = "schedule-module--schedule-end--2MIEE";
export var scheduleSandy = "schedule-module--schedule-sandy--G5NYV";
export var scheduleGreen = "schedule-module--schedule-green--iQG09";
export var timeBlock = "schedule-module--time-block--2MYgz";
export var lecture = "schedule-module--lecture--3Yg4y";
export var lectureTime = "schedule-module--lecture-time--28Mdf";
export var lectureTitle = "schedule-module--lecture-title--1Is3R";
export var lectureTitleDashed = "schedule-module--lecture-title--dashed--2Plg0";
export var speaker = "schedule-module--speaker--2k685";
export var photo = "schedule-module--photo--3gqSn";
export var line = "schedule-module--line--2LxkW";
export var mobile = "schedule-module--mobile--b_qhQ";
export var titleWrapper = "schedule-module--title-wrapper--2Kgz2";
export var hidden = "schedule-module--hidden--1ywvF";
export var tooltip = "schedule-module--tooltip--9XfJg";