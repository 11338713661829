export const cls = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

export const getImageNode = (images, relativePath) => {
  const image = images.edges.find((e) => e.node.relativePath === relativePath)

  if (image) {
    return image.node
  }

  return ''
}

export const lastDigitToWord = (digit, unit) => {
  const lastFigure = parseInt(digit.toString().substr(digit.toString().length - 1, 1))
  if (digit >= 11 && digit < 15) {
    switch (unit) {
      case 'days':
        return 'Дней'
      case 'hours':
        return 'Часов'
      case 'minutes':
        return 'Минут'
      case 'seconds':
        return 'Секунд'
      default:
        return ''
    }
  } else {
    if (lastFigure === 1) {
      switch (unit) {
        case 'days':
          return 'День'
        case 'hours':
          return 'Час'
        case 'minutes':
          return 'Минута'
        case 'seconds':
          return 'Секунда'
        default:
          return ''
      }
    }
    if (lastFigure > 1 && lastFigure < 5) {
      switch (unit) {
        case 'days':
          return 'Дня'
        case 'hours':
          return 'Часа'
        case 'minutes':
          return 'Минуты'
        case 'seconds':
          return 'Секунды'
        default:
          return ''
      }
    }
    if (lastFigure === 0 || lastFigure >= 5) {
      switch (unit) {
        case 'days':
          return 'Дней'
        case 'hours':
          return 'Часов'
        case 'minutes':
          return 'Минут'
        case 'seconds':
          return 'Секунд'
        default:
          return ''
      }
    }
  }
}
