// extracted by mini-css-extract-plugin
export var container = "title-module--container--aNkUJ";
export var anchor = "title-module--anchor--3tq4a";
export var title = "title-module--title--oQFAC";
export var bigCircle = "title-module--bigCircle--1QwN9";
export var sandy = "title-module--sandy--1upBu";
export var sandyCircle = "title-module--sandy-circle--3lWd-";
export var orange = "title-module--orange--ZmLuc";
export var orangeCircle = "title-module--orange-circle--22AO5";
export var green = "title-module--green--3hXx9";
export var greenCircle = "title-module--green-circle--6McYY";
export var greenDark = "title-module--green-dark--1Lzgb";
export var greenDarkCircle = "title-module--green-dark-circle--qzM5N";