// extracted by mini-css-extract-plugin
export var container = "main-module--container--3osH7";
export var ballSandy = "main-module--ball-sandy--CuapM";
export var ballMove = "main-module--ball-move--3fXi9";
export var ballSandyDark = "main-module--ball-sandy-dark--3SkDQ";
export var ballMainSubtitle = "main-module--ball-main-subtitle--w4sv-";
export var ballGreen = "main-module--ball-green--1JURo";
export var ballGreenBig = "main-module--ball-green-big--hKatb";
export var logoMove = "main-module--logo-move--q1355";
export var circleSandy = "main-module--circle-sandy--22icZ";
export var circleMove = "main-module--circle-move--I25s1";
export var circleGreen = "main-module--circle-green--2QQsZ";
export var bg = "main-module--bg--2ufx2";
export var background = "main-module--background--VLZlh";
export var content = "main-module--content--1aqZT";
export var logo = "main-module--logo--2SEaY";
export var info = "main-module--info--2oAHd";
export var infoText = "main-module--info-text--3VUI6";
export var sub = "main-module--sub--2ILJQ";
export var infoTimer = "main-module--info-timer--djTat";
export var timer = "main-module--timer--ku_GC";
export var timeBlock = "main-module--time-block--3HffH";
export var time = "main-module--time--1Z7aK";
export var text = "main-module--text--2Yogs";
export var colon = "main-module--colon--1Zq4O";