import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import * as styles from './main.module.scss'
import logoLeaf from '../../images/logo-leaf.svg'
import bg from '../../images/reduced-bg.png'
import bgMobile from '../../images/bg-mobile.png'
import colon from '../../images/colon.svg'
import { cls, lastDigitToWord } from '../../utils/utils'

const getWithZero = (digit) => {
  if (digit < 10) {
    return `0${digit}`
  }

  return digit
}

const getTimeParts = (countDownDate) => {
  const now = new Date().getTime()

  const distance = countDownDate - now

  const days = Math.floor(distance / (1000 * 60 * 60 * 24))
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((distance % (1000 * 60)) / 1000)

  return {
    days: days > 0 ? days : 0,
    hours: hours > 0 ? hours : 0,
    minutes: minutes > 0 ? minutes : 0,
    seconds: seconds > 0 ? seconds : 0,
    distance,
  }
}

export const MainTemplate = () => {
    const countDownDate = new Date('Nov 14, 2021 10:00:00').getTime()

  const startTime = getTimeParts(countDownDate)

  const [days, setDays] = useState(startTime.days)
  const [hours, setHours] = useState(startTime.hours)
  const [minutes, setMinutes] = useState(startTime.minutes)
  const [seconds, setSeconds] = useState(startTime.seconds)

  const updateTime = useCallback(
    (x) => {
      const { days, hours, minutes, seconds, distance } = getTimeParts(countDownDate)

      setDays(days)
      setHours(hours)
      setMinutes(minutes)
      setSeconds(seconds)

      if (distance <= 0) {
        clearInterval(x)
      }
    },
    [countDownDate]
  )

  useEffect(() => {
    const x = setInterval(() => {
      updateTime(x)
    }, 1000)
  }, [updateTime])

  return (
    <div className={styles.container}>
      <div className={styles.ballSandy} />
      <div className={styles.ballSandyDark} />
      <div className={styles.ballGreen} />
      <div className={styles.ballGreenBig}>
        <div>
          <img src={logoLeaf} alt=""  />
        </div>
      </div>
      <div className={styles.circleSandy} />
      <div className={styles.circleGreen} />
      <picture>
        <source srcSet={bgMobile} media="(max-width: 1050px)" />
          <img src={bg} className={styles.bg} alt="" />
      </picture>

      <div className={styles.content}>

        <div className={cls(styles.info, 'main-container', 'grid-12')}>
          <div className={styles.infoText}>
            <h4>
              ITeaConf — большая техническая конференция о веб-разработке. 2 потока: frontend и backend. 15+ спикеров из Воронежа, Москвы, Санкт-Петербурга, Новосибирска, Ростова-на-Дону, Краснодара.
            </h4>
            <p className={styles.sub}>
              Несколько десятков докладов и дискуссий обо всем, что происходит в IT-мире сегодня. Тот самый networking, общение со спикерами.
            </p>
          </div>
          <div className={styles.infoTimer}>
            <h4>До начала осталось</h4>
            <div className={styles.timer} id="clock">
              <div className={styles.timeBlock}>
                <p className={styles.time}>{getWithZero(days)}</p>
                <p className={styles.text}>{lastDigitToWord(days, 'days')}</p>
              </div>
              <div className={styles.timeBlock}>
                <img src={colon} className={styles.colon} alt="" />
              </div>
              <div className={styles.timeBlock}>
                <p className={styles.time}>{getWithZero(hours)}</p>
                <p className={styles.text}>{lastDigitToWord(hours, 'hours')}</p>
              </div>
              <div className={styles.timeBlock}>
                <img src={colon} className={styles.colon} alt="" />
              </div>
              <div className={styles.timeBlock}>
                <p className={styles.time}>{getWithZero(minutes)}</p>
                <p className={styles.text}>{lastDigitToWord(minutes, 'minutes')}</p>
              </div>
              <div className={styles.timeBlock}>
                <img src={colon} className={styles.colon} alt="" />
              </div>
              <div className={styles.timeBlock}>
                <p className={styles.time}>{getWithZero(seconds)}</p>
                <p className={styles.text}>{lastDigitToWord(seconds, 'seconds')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
