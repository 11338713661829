import * as React from 'react'
import { Title } from '../../components/title/title'
import * as styles from './organizers.module.scss'
import quantori from '../../images/organizers/quantori.svg'
import dsr from '../../images/organizers/dsr.svg'
import evrone from '../../images/organizers/evrone.svg'
import dataArt from '../../images/organizers/data-art.svg'
import beerJSVRN from '../../images/organizers/beerjs_vrn.png'
import { cls } from '../../utils/utils'

export const OrganizersTemplate = () => {
  return (
    <section className={styles.section}>
      <Title color='green' id='partners' className={styles.title}>
        Организаторы
      </Title>

      <div className={cls('main-container', styles.organizers, styles.desktop)}>
        <a href='https://www.quantori.com/' target='_blank' className={styles.organizersLink}>
          <img src={quantori} className={styles.logo} alt='' />
        </a>
        <a href='https://ru.dsr-corporation.com/examination' target='_blank' className={styles.organizersLink}>
          <img src={dsr} className={styles.logo} alt='' />
        </a>
        <a href='https://github.com/beerjs/voronezh/' target='_blank' className={styles.organizersLink}>
          <img src={beerJSVRN} className={styles.logo} alt='' />
        </a>
        <a href='https://evrone.ru/' target='_blank' className={styles.organizersLink}>
          <img src={evrone} className={styles.logo} alt='' />
        </a>
        <a href='https://dataart.team/' target='_blank' className={styles.organizersLink}>
          <img src={dataArt} className={styles.logo} alt='' />
        </a>
      </div>

      <div className={cls('main-container', styles.organizers, styles.mobile)}>
        <div className={styles.logoContainer}>
           <a href='https://www.quantori.com/' target='_blank' className={styles.organizersLink}>
          <img src={quantori} className={styles.logo} alt='' />
           </a>
        </div>
        <div className={styles.logoContainer}>
           <a href='https://ru.dsr-corporation.com/examination' target='_blank' className={styles.organizersLink}>
          <img src={dsr} className={styles.logo} alt='' />
           </a>
        </div>
        <div className={styles.logoContainer}>
           <a href='https://github.com/beerjs/voronezh/' target='_blank' className={styles.organizersLink}>
          <img src={beerJSVRN} className={styles.logo} alt='' />
           </a>
        </div>
        <div className={styles.logoContainer}>
           <a href='https://evrone.ru/' target='_blank' className={styles.organizersLink}>
          <img src={evrone} className={styles.logo} alt='' />
           </a>
        </div>
        <div className={styles.logoContainer}>
           <a href='https://dataart.team/' target='_blank' className={styles.organizersLink}>
          <img src={dataArt} className={styles.logo} alt='' />
           </a>
        </div>
      </div>
    </section>
  )
}
