import * as React from 'react'
import { cls } from '../../utils/utils'
import * as styles from './button.module.scss'

export const Button = (props) => {
  const { btnStyle, children, className, ...rest } = props
  return (
    <button className={cls(styles.button, 'text--button', styles[`${btnStyle}`], className)} {...rest}>
      {children}
    </button>
  )
}
