import * as React from 'react'
import { Title } from '../../components/title/title'
import * as styles from './comittee.module.scss'
import { cls, getImageNode } from '../../utils/utils'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

export const CommitteeTemplate = () => {
  const { data, avatars } = useStaticQuery(query)
  const { committee } = data

  return (
    <section className={styles.section}>
      <div className={styles.footerBgWrapper}>
        <div className={styles.footerBg} />
      </div>

      <Title color='orange' id='committee' className={styles.title}>
        Программный комитет
      </Title>

      <div className={cls('main-container', styles.committee)}>
        {committee.map((c, i) => (
          <div className={cls(styles.people, styles[`people${i}`])} key={i}>
            <GatsbyImage
              className={cls(styles.photo, styles[`photo${i}`])}
              image={getImageNode(avatars, c.photo).childImageSharp.gatsbyImageData}
              alt=''
            />
            <div className={styles.info}>
              <h4 className={styles.name}>{c.name}</h4>
              <p>{c.position}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

const query = graphql`
  query Committee {
    data: contentJson {
      committee {
        name
        photo
        position
      }
    }

    avatars: allFile(filter: { relativePath: { regex: "/committee//" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`
