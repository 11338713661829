import * as React from 'react'
import { Title } from '../../components/title/title'
import * as styles from './schedule.module.scss'
import { cls, getImageNode } from '../../utils/utils'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Tippy from '@tippyjs/react'

const renderTimeBlock = (lectures, hallName, avatars) => {
  const getTechLabelColorClass = () => {
    if (hallName === 'firstHall') return styles.techLabelSandy
    if (hallName === 'secondHall') return styles.techLabelGreen
    return ''
  }
  return (
    <div className={styles.timeBlock}>
      {lectures.map((l, i) => (
        <div className={cls(styles.lecture, l.title === '' && styles.hidden)} key={`${l.time}-${hallName}-${i}`}>
          <h3 className={styles.lectureTime}>{l.time || ' '}</h3>
          <div>
            {
              l.description ?
                <Tippy content={<span>{l.description}</span>} placement={'bottom'} className={styles.tooltip}>
                  <h4 className={cls(styles.lectureTitle, styles.lectureTitleDashed)}>
                    {l.title}
                    {l.speaker && l.speaker.technology &&
                    <div className={cls(styles.techLabel, styles.lectureTechLabel, getTechLabelColorClass())}>
                      {l.speaker.technology}
                    </div>
                    }
                  </h4>
                </Tippy> :
                <h4 className={styles.lectureTitle}>
                  {l.title}
                  {l.speaker && l.speaker.technology &&
                  <div className={cls(styles.techLabel, styles.lectureTechLabel, getTechLabelColorClass())}>
                    {l.speaker.technology}
                  </div>
                  }
                </h4>
            }
            {l.speaker && (
              <div className={styles.speaker}>
                {getImageNode(avatars, l.speaker.photo) && (
                  <GatsbyImage
                    className={styles.photo}
                    image={getImageNode(avatars, l.speaker.photo).childImageSharp.gatsbyImageData}
                    alt=""
                  />
                )}
                <div>
                  <div className={cls('text--normal')}>{l.speaker.name}</div>
                  <div className={styles.speakerCaption}>
                    {[l.speaker.position,l.speaker.company].filter(item => item).join(', ')}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export const ScheduleTemplate = () => {
  const { data, avatars } = useStaticQuery(query)
  const { schedule } = data
  const { times } = schedule
  const opening = times[0]
  const ending = times[times.length - 1]

  return (
    <div className='main-container'>
      <section className={styles.section}>
        <div className={styles.sectionTitle}>
          <Title color="sandy" id="schedule">
            {schedule.title}
          </Title>
          <p className={styles.titleCaption}>Московское время (GMT+3)</p>
        </div>
        <div className={styles.desktop}>
          <div className={cls('grid-12', styles.grid)}>
            <span className={cls(styles.time, styles.timeStart)}>{opening.time}</span>

            <div className={cls(styles.schedule, styles.scheduleStart, styles.scheduleSandy)}>
              <h2>{schedule.firstHallName}</h2>

              {renderTimeBlock(opening.firstHall.lectures, 'firstHall', avatars)}
            </div>

            <div className={cls(styles.schedule, styles.scheduleStart, styles.scheduleGreen)}>
              <h2>{schedule.secondHallName}</h2>

              {renderTimeBlock(opening.secondHall.lectures, 'secondHall', avatars)}
            </div>
          </div>

          {times.slice(1, times.length - 1).map((t, i) => (
            <div className={cls('grid-12', styles.grid)} key={`${t.time}-${i}`}>
              <div className={styles.line} />

              <span className={styles.time}>{t.time}</span>

              <div className={cls(styles.schedule, styles.scheduleSandy)}>
                {renderTimeBlock(t.firstHall.lectures, 'firstHall', avatars)}
              </div>

              <div className={cls(styles.schedule, styles.scheduleGreen)}>
                {renderTimeBlock(t.secondHall.lectures, 'secondHall', avatars)}
              </div>
            </div>
          ))}

          <div className={cls('grid-12', styles.grid)}>
            <div className={styles.line} />

            <span className={styles.time}>{ending.time}</span>

            <div className={cls(styles.schedule, styles.scheduleEnd, styles.scheduleSandy)}>
              {renderTimeBlock(ending.firstHall.lectures, 'firstHall', avatars)}
            </div>

            <div className={cls(styles.schedule, styles.scheduleEnd, styles.scheduleGreen)}>
              {renderTimeBlock(ending.secondHall.lectures, 'secondHall', avatars)}
            </div>
          </div>
        </div>

        <div className={styles.mobile}>
          <div className={cls(styles.schedule, styles.scheduleSandy)}>
            <h2 className={styles.title}>{schedule.firstHallName}</h2>

            {times.map((t, i) => (
              <div key={i}>{renderTimeBlock(t.firstHall.lectures, 'firstHall', avatars)}</div>
            ))}
          </div>

          <div className={cls(styles.schedule, styles.scheduleGreen)}>
            <h2 className={styles.title}>{schedule.secondHallName}</h2>

            {times.map((t, i) => (
              <div key={i}>{renderTimeBlock(t.secondHall.lectures, 'secondHall', avatars)}</div>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}

const query = graphql`
  query Schedule {
    data: contentJson {
      schedule {
        title
        firstHallName
        secondHallName
        times {
          firstHall {
            lectures {
              time
              title
              description
              speaker {
                name
                photo
                technology
                company
                position
              }
            }
          }
          time
          secondHall {
            lectures {
              time
              title
              description
              speaker {
                name
                photo
                technology
                company
                position
              }
            }
          }
        }
      }
    }

    avatars: allFile(filter: { relativePath: { regex: "/speakers//" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(
              width: 32
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`
