import * as React from 'react'
import { cls } from '../../utils/utils'
import * as styles from './header.module.scss'
import { Button } from '../button/button'
import { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import {timepadEventId} from '../../constants'

export const Header = () => {
  const [sticky, setSticky] = useState(false)

  const listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop

    if (winScroll > 0) {
      setSticky(true)
    }

    if (winScroll === 0) {
      setSticky(false)
    }
  }

  useEffect(() => {
    listenToScroll()
    document.addEventListener('scroll', listenToScroll)

    return () => {
      window.removeEventListener('scroll', listenToScroll)
    }
  }, [])

  return (
    <header className={cls(styles.header, sticky ? styles.sticky : '')}>
      <div className={cls('main-container', styles.container)}>
        <Link to="#" className={styles.titleLink}>
          <h1 className={styles.title}>ITeaConf</h1>
        </Link>
        <div className={styles.links}>
          <Link to="#speakers" className={styles.link}>
            Спикеры
          </Link>
          <Link to="#schedule" className={styles.link}>
            Расписание
          </Link>
          <Link to="#partners" className={styles.link}>
            Партнёры
          </Link>
          <Link to="#committee" className={styles.link}>
            Программный комитет
          </Link>
          <Button id={`timepad_twf_register_${timepadEventId}`} btnStyle={sticky ? 'blackHollow' : 'whiteHollow'} className={styles.btn}>
            Регистрация
          </Button>
        </div>
      </div>
    </header>
  )
}
