import * as React from 'react'
import { ScheduleTemplate } from '../template/schedule/schedule'
import { Header } from '../components/header/header'
import { SpeakersTemplate } from '../template/speakers/speakers'
import { MainTemplate } from '../template/main/main'
import { OrganizersTemplate } from '../template/organizers/organizers'
import { CommitteeTemplate } from '../template/committee/comittee'
import { PartnersTemplate } from '../template/partners/partners'
import { ContactsTemplate } from '../template/contacts/contacts'
import { Helmet } from 'react-helmet'
import { timepadEventId, timepadCustomized, yandexMetrikaTrackingId, fbTrackingId } from '../constants'

const IndexPage = () => {
  const getTimepadOptions = () => {
    return {
      __html: `(function () {
            return {
              event: { id: ${timepadEventId} },
              hidePreloading: true,
              display: 'popup',
              popup: { triggerSelector: '#timepad_twf_register_${timepadEventId}' },
            }
          })();`,
    }
  }
  return (
    <>
      <Helmet title={'ITeaConf'}
              meta={
                [
                  { 'name': 'facebook-domain-verification', content: 'l7tlo0vr0h5frn1pctgb4vl1a8fz8i' },
                ]
              }>
        <script
          async='async'
          defer='defer'
          data-timepad-widget-v2='event_register'
          data-timepad-customized={timepadCustomized}
          src='https://timepad.ru/js/tpwf/loader/min/loader.js'
          dangerouslySetInnerHTML={getTimepadOptions()}
        />
        {/*Yandex.Metrika counter*/}
        <script>
          {/* TODO Need to load script only on production */}
          {`
             (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
             m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
             (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
             ym(${yandexMetrikaTrackingId}, "init", {
                  clickmap:true,
                  trackLinks:true,
                  accurateTrackBounce:true,
                  webvisor:true
             });
          `}
        </script>
        {/*Yandex.Metrika counter*/}

        {/* Facebook Pixel Code */}
        <script>
          {/* TODO Need to load script only on production */}
          {`
         !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', ${fbTrackingId});
          fbq('track', 'PageView');
          `}
        </script>
        {/* End Facebook Pixel Code */}

        {/* VK Code */}
        <script>
          {/* TODO Need to load script only on production */}
          {`
            !function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src="https://vk.com/js/api/openapi.js?169",t.onload=function(){VK.Retargeting.Init("VK-RTRG-1109252-4PFuh"),VK.Retargeting.Hit()},document.head.appendChild(t)}();
           `}
        </script>
        {/* End VK Code */}
      </Helmet>
      <Header />
      <main>
        <MainTemplate />
        <SpeakersTemplate />
        <ScheduleTemplate />
        <OrganizersTemplate />
        <PartnersTemplate />
        <CommitteeTemplate />
        <ContactsTemplate />
      </main>
    </>
  )
}

export default IndexPage
