import * as React from 'react'
import { Title } from '../../components/title/title'
import * as styles from './speakers.module.scss'
import { cls, getImageNode } from '../../utils/utils'
import { graphql, useStaticQuery } from 'gatsby'
import { Button } from '../../components/button/button'
import arrow from '../../images/arrow-down.svg'
import { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

export const SpeakersTemplate = () => {
  const { data, avatars } = useStaticQuery(query)
  const { speakers } = data

  const [isAllSpeakers, setIsAllSpeakers] = useState(false)

  return (
    <div className="main-container">
      <section className={styles.section}>
        <Title color="greenDark" id="speakers" className={styles.title}>
          Спикеры
        </Title>

        <div className={cls('grid-12', styles.speakers)}>
          {speakers.map((s, i) => (
            <div className={cls(styles.speaker, !isAllSpeakers ? styles.hidden : '')} key={i}>
              {getImageNode(avatars, s.photo) ? (
                <GatsbyImage className={styles.photo} image={getImageNode(avatars, s.photo).childImageSharp.gatsbyImageData} alt="" />
              ) : (
                <div className={cls(styles.photo, styles.photoEmpty)} />
              )}
              <h4 className={styles.alignCenter}>{s.name}</h4>
              <p className={cls('text--normal', styles.alignCenter)}>{s.position}</p>
            </div>
          ))}
        </div>

        {!isAllSpeakers && (
          <div className={styles.btnContainer}>
            <Button btnStyle="blackHollow" onClick={() => setIsAllSpeakers(true)}>
              <div className={styles.btn}>
                Все спикеры
                <img src={arrow} className={styles.icon} alt="" />
              </div>
            </Button>
          </div>
        )}
      </section>
    </div>
  )
}

const query = graphql`
  query Speakers {
    data: contentJson {
      speakers {
        name
        photo
        position
      }
    }

    avatars: allFile(filter: { relativePath: { regex: "/speakers//" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`
