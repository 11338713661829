// extracted by mini-css-extract-plugin
export var section = "speakers-module--section--2MCc9";
export var title = "speakers-module--title--3D0n5";
export var speakers = "speakers-module--speakers--TIaqM";
export var speaker = "speakers-module--speaker--LgmAB";
export var photo = "speakers-module--photo--2GIyw";
export var photoEmpty = "speakers-module--photo-empty--3YsBF";
export var alignCenter = "speakers-module--align-center--3Wydz";
export var hidden = "speakers-module--hidden--3W9wI";
export var btnContainer = "speakers-module--btn-container--1EQwz";
export var btn = "speakers-module--btn--U3L0p";
export var icon = "speakers-module--icon--26JRA";