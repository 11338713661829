// extracted by mini-css-extract-plugin
export var section = "comittee-module--section--tKzv3";
export var title = "comittee-module--title--2tV1A";
export var footerBgWrapper = "comittee-module--footer-bg-wrapper--3oRBy";
export var footerBg = "comittee-module--footer-bg--xZR4U";
export var committee = "comittee-module--committee--3US-7";
export var people = "comittee-module--people--191zc";
export var people1 = "comittee-module--people-1--1CvvW";
export var people5 = "comittee-module--people-5--Diaz9";
export var people2 = "comittee-module--people-2--2V4Jl";
export var people4 = "comittee-module--people-4--3NZUE";
export var people3 = "comittee-module--people-3--pArdy";
export var people6 = "comittee-module--people-6--16VaA";
export var photo = "comittee-module--photo--26Bvg";
export var photo1 = "comittee-module--photo-1--3TLbG";
export var photo5 = "comittee-module--photo-5--79AUt";
export var photo2 = "comittee-module--photo-2--30wSK";
export var photo6 = "comittee-module--photo-6--2lRf0";
export var photo3 = "comittee-module--photo-3--1KgUh";
export var info = "comittee-module--info--zyOAr";
export var name = "comittee-module--name--1ueR6";