import * as React from 'react'
import { Title } from '../../components/title/title'
import * as styles from './contacts.module.scss'
import { cls } from '../../utils/utils'
import vk from '../../images/social/vk.svg'
import fb from '../../images/social/fb.svg'
import youtube from '../../images/social/youtube.svg'

export const ContactsTemplate = () => {

  return (
    <div>
      <section className={styles.section}>
        <div  className="main-container">
          <Title color='sandy' id='contacts' className={styles.title}>
          Контакты
        </Title>
          <div className={styles.social}>
            <div className={cls(styles.socialEmail, styles.socialItem)}>
              <a href="mailto:info@iteaconf.ru"
                 className={styles.socialLink}
                 target="_blank"
              >
                info@iteaconf.ru
              </a>
            </div>
            <div className={cls(styles.socialEmail, styles.socialItem)}>
              <a href="https://vk.com/iteaconf"
                 className={styles.socialLink}
                 target="_blank"
              >
                <img src={vk} alt='' />
              </a>
            </div>
            <div className={cls(styles.socialEmail, styles.socialItem)}>
              <a href="https://www.facebook.com/ITeaConf"
                 className={styles.socialLink}
                 target="_blank"
              >
                <img src={fb} alt='' />
              </a>
            </div>
            <div className={cls(styles.socialEmail, styles.socialItem)}>
              <a href="https://www.youtube.com/channel/UCbdJCW3jOKREqBmhtY1C4nA/featured"
                 className={styles.socialLink}
                 target="_blank"
              >
                <img src={youtube} alt='' />
              </a>
            </div>
          </div>
        </div>
        {/*<div>*/}
        {/*  <div style={{ position: 'relative', overflow: 'hidden' }}>*/}
        {/*    <a href="https://yandex.ru/maps/org/prostranstvo_vinzavod/112110373478/?utm_medium=mapframe&utm_source=maps"*/}
        {/*       style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 0 }}>Пространство Винзавод</a><a*/}
        {/*    href="https://yandex.ru/maps/193/voronezh/category/rental_of_venues_for_cultural_events/15215599314/?utm_medium=mapframe&utm_source=maps"*/}
        {/*    style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: '14px' }}>Аренда площадок для культурно-массовых*/}
        {/*    мероприятий в Воронеже</a>*/}
        {/*    <a href="https://yandex.ru/maps/193/voronezh/category/cafe/184106390/?utm_medium=mapframe&utm_source=maps"*/}
        {/*       style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: '28px' }}>Кафе в Воронеже</a>*/}
        {/*    <iframe src="https://yandex.ru/map-widget/v1/-/CCUqiIXIHB" width="100%" height="500" frameBorder="1"*/}
        {/*            title="Yandex map"*/}
        {/*            allowFullScreen={true} style={{position: 'relative'}} />*/}
        {/*  </div>*/}
        {/*</div>*/}
      </section>
    </div>
  )
}
